import React, { useEffect, useRef, useState } from 'react';
import {
	Col,
	Row,
	Spin,
	List,
	Button,
	Space,
	Divider,
	Popover,
	message,
	Menu,
	Dropdown,
	Modal,
} from 'antd';
import { useParams } from 'react-router-dom';
import { ChannelCard } from './ChannelCard';
import { Typography } from 'antd';
import { UserAvatar } from '../util/Avatar';
import { MoreOutlined, WarningFilled } from '@ant-design/icons';
import { useQueryClient, UseQueryResult } from 'react-query';
import { MediaType, useMediaUpload, usePutMedia } from '../../hooks/useREST';
import {
	AccessLevel,
	CompanyInvitesQuery,
	CompanyQuery,
	EuCountryCodes,
	FeatureNames,
	SetCompanyOwnerMutation,
	UpdateCompanySettingsInput,
	useCompanyInvitesQuery,
	useCompanyQuery,
	useRemoveCompanyMutation,
	useRemoveCompanyUserMutation,
	useSelfQuery,
	useSetCompanyOwnerMutation,
	useSetMemberLevelMutation,
	useUpdateCompanyMutation,
	useUpdateOnboardingMutation,
} from '../../generated/graphql';
import { useGQLMutationWithFiles } from '../../hooks/useGQL';
import { gql } from 'graphql-request';
import { Breadcrumb } from './Breadcrumb';
import { useTranslation } from 'react-i18next';
import { OnBoarding } from 'antd-onboarding';
import { useAuthStore } from '../../hooks/useAuth';
import { CurrentPlanTag } from './Plans';
import { Tag, TagAccessLevel } from '../util/Tag';
import { AddUserModal, AddUserModalProps } from './AddUserModal';
import { InviteUserModal } from './CompanyModal';
import { FeatureLock, useFeatureLock } from './FeatureLock';
import { CreateChannelModal } from './CreateChannelModal';
import { Helmet } from 'react-helmet';
import { UserInfo } from './UserSelect';
import { CompanySettings } from '../CompanySettings';
import { accessLevelAbove, getDateFnsLocale } from '../../lib/util';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { useNavigate } from 'react-router';
import formatRelative from 'date-fns/formatRelative';
import format from 'date-fns/format';
import { CompanyApiKeys } from '../CompanyApiKeys';
import { useFeature } from '../../hooks/useFeatures';
import MenuItem from 'antd/lib/menu/MenuItem';
import { MenuItemProps } from 'antd/node_modules/rc-menu';
import { captureException } from '@sentry/react';
import { Input } from '../util/Input';
import CompanyRemovedInfo from '../CompanyRemovedInfo';
import { Alert } from '../util/Alert';

const { Title, Text } = Typography;

const PendingInvites = ({ data, isLoading }: UseQueryResult<CompanyInvitesQuery>) => {
	const { t, i18n } = useTranslation('translations', {
		keyPrefix: 'CompanyOverview.PendingInvites',
	});
	const dateFnsLocale = getDateFnsLocale(i18n.language);
	if (data?.company.invites.length === 0) return null;
	return (
		<Row style={{ marginBottom: '16px' }}>
			<Col span={24}>
				<Title level={4}>{t('Title')}</Title>
			</Col>
			{isLoading ? (
				<Spin />
			) : (
				data?.company.invites.map((invite) => (
					<Col key={invite.created} span={24} style={{ padding: '8px 16px' }}>
						<Row justify="space-between" align="middle">
							<Col>
								<Space size={2} direction="vertical">
									<Typography.Text type="secondary">{t('SentTo')}</Typography.Text>
									<Typography.Text>{invite.email}</Typography.Text>
								</Space>
							</Col>
							<Col>
								<Space size={2} direction="vertical">
									<Typography.Text type="secondary">{t('Created')}</Typography.Text>
									<Typography.Text>
										{format(new Date(invite.created), 'Pp', { locale: dateFnsLocale })}
									</Typography.Text>
								</Space>
							</Col>
							<Col>
								<Space size={2} direction="vertical">
									<Typography.Text type="secondary">{t('Expires')}</Typography.Text>
									<Typography.Text>
										{formatRelative(new Date(invite.expires), new Date(invite.created), {
											locale: dateFnsLocale,
										})}
									</Typography.Text>
								</Space>
							</Col>
						</Row>
					</Col>
				))
			)}
		</Row>
	);
};

export const RemoveCompanyModal = ({
	show = false,
	id,
	name,
	onClose,
}: {
	show?: boolean;
	id: number;
	name: string;
	onClose: () => void;
}) => {
	const { t } = useTranslation('translations', {
		keyPrefix: 'CompanyOverview.RemoveCompany',
	});
	const [deletionMessage, setDeletionMessage] = useState<string>();
	const navigate = useNavigate();
	const { mutateAsync: removeCompany, isLoading } = useRemoveCompanyMutation();
	const resetSelectedCompany = useSelectedCompanyStore.useReset();

	const [showInfoNeedResolveIncidents, setShowInfoNeedResolveIncidents] = useState(false);

	const onRemoveCompany = async () => {
		try {
			await removeCompany({ input: { companyId: id, message: deletionMessage } });
			resetSelectedCompany();
			navigate('/admin/dashboard');
			message.success(t('Message.Success'));
			onClose();
		} catch (err: any) {
			if (
				err.response?.errors[0].extensions?.code === 'FORBIDDEN' &&
				err.response?.errors[0].message ===
					'Cannot delete company with unresolved incident reports'
			) {
				setShowInfoNeedResolveIncidents(true);
			} else {
				message.error(t('Message.Error'));
			}
		}
	};

	return (
		<Modal
			title={t('Title', { name })}
			visible={show}
			onOk={onRemoveCompany}
			onCancel={onClose}
			okText={t('Ok')}
			cancelText={t('Cancel')}
			okType="danger"
			confirmLoading={isLoading}
		>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Typography.Text>{t('MessageInput.Label')}</Typography.Text>
				<Input.TextArea
					maxLength={512}
					value={deletionMessage}
					onChange={(e) => setDeletionMessage(e.target.value)}
					placeholder={t('MessageInput.Placeholder')}
				/>
				{showInfoNeedResolveIncidents && (
					<Alert
						type="warning"
						description={t('Alert.NeedIncidentResolution.Description')}
					/>
				)}
			</Space>
		</Modal>
	);
};

export const CompanyOverview = () => {
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', { keyPrefix: 'CompanyOverview' });
	const { t: tAccessLevel } = useTranslation('translations', {
		keyPrefix: 'AccessLevel',
	});
	const queryClient = useQueryClient();
	const { locked: companyApiKeysFeatureLocked } = useFeatureLock(
		FeatureNames.CompanyApiKey,
	);
	const [createModalVisible, setCreateModalVisible] = useState(false);
	const [addModalVisible, setAddModalVisible] = useState(false);
	const [selectOwnerModalVisible, setSelectOwnerModalVisible] = useState(false);
	const [onboardingVisible, setOnboardingVisible] = useState(false);
	const { mutateAsync: updateAdmin } = useSetCompanyOwnerMutation();
	const { mutateAsync: setMemberLevel } = useSetMemberLevelMutation();
	const fileInputElem = useRef<HTMLInputElement | null>(null);
	const { id: idParam } = useParams();
	if (idParam === undefined) throw new Error(tt('MissingUrlParam'));
	const { data, isLoading, isError, refetch } = useCompanyQuery({
		id: parseInt(idParam),
	});
	const setSelectedCompany = useSelectedCompanyStore.useSetCompany();
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const {
		data: dataSelf,
		isLoading: selfIsLoading,
		isSuccess: selfIsSuccess,
		isError: selfIsError,
	} = useSelfQuery({}, { enabled: isAuthenticated });
	const companyInvitesQuery = useCompanyInvitesQuery(
		{ id: parseInt(idParam) },
		{ enabled: isAuthenticated && !isLoading && !isError },
	);
	const { mutateAsync: mutateRemoveUser } = useRemoveCompanyUserMutation();
	const { mutateAsync: postAvatar } = useMediaUpload(MediaType.IMAGE, {
		enabled: !isLoading && !isError,
	});
	const { mutateAsync: putAvatar } = usePutMedia({ enabled: !isLoading && !isError });
	const { mutateAsync: updateCompany } = useUpdateCompanyMutation();
	const { mutateAsync: setCompanyLogo } = useGQLMutationWithFiles(gql`
		mutation ($id: Int!, $file: Upload) {
			setCompanyLogo(id: $id, file: $file, extension: "png") {
				logo {
					id
					uri
				}
			}
		}
	`); // TODO: magic string
	const updateOnboarding = useUpdateOnboardingMutation();
	const [refetchHandle, setRefetchHandle] = useState<() => void | null>();
	const selfAccessLevel = useSelectedCompanyStore(
		(state) => state.company?.selfAccessLevel,
	);

	const [showRemoveCompanyModal, setShowRemoveCompanyModal] = useState(false);

	useEffect(() => {
		if (data?.company && data.company.deletion == null) setSelectedCompany(data.company);
	}, [data]);

	const removeUser = (
		company: CompanyQuery['company'],
		member: CompanyQuery['company']['members'][0],
	) => {
		mutateRemoveUser({ companyId: company.id, userId: member.user.id })
			.then((res) => {
				console.log(res);
				const removedId = res.removeCompanyUser.id;
				if (data)
					data.company.users = data.company.users.filter(
						(u: any) => !(u.id === removedId),
					);
				queryClient.invalidateQueries('company');
				queryClient.invalidateQueries('self');
			})
			.catch(captureException);
	};

	const onAvatarSelect = (e: any) => {
		const file = e.target.files[0];
		setCompanyLogo([{ id: company.id, file: null }, new Map([['variables.file', file]])])
			.then((res) => {
				queryClient.invalidateQueries('selfCompaniesQuery');
				queryClient.invalidateQueries('company');
				if (refetchHandle) {
					refetchHandle();
				}
			})
			.catch((err) => {
				captureException(err);
				message.error('Failed to upload avatar');
			});
	};

	const openFileInput = () => {
		fileInputElem.current && fileInputElem.current.click();
	};

	if (isError || selfIsError) return <WarningFilled />;
	if (isLoading || selfIsLoading || !data || !dataSelf) {
		return (
			<Row justify="center" align="middle">
				<Col>
					<Spin />
				</Col>
			</Row>
		);
	}
	if (data?.company && data.company.deletion) {
		return (
			<CompanyRemovedInfo
				name={data.company.name}
				message={data.company.deletion.message}
				backRoute="/admin/dashboard"
			/>
		);
	}

	const company = data?.company;
	const avatarData = {
		surname: company.name.slice(0, 1),
		lastname: company.name.slice(1),
		uri: company.logo?.uri,
		id: company.logo?.id,
	};

	const createEditable = (property: 'name' | 'code', length?: number) => {
		return {
			length,
			onChange: async (x: any) => {
				if (x === company[property]) return;
				data.company[property] = x;
				await updateCompany({ companyId: company.id, update: { [property]: x } });
				refetch();
			},
		};
	};

	const createAddressEditable = (
		property: 'street' | 'housenumber' | 'postcode' | 'city' | 'recipientName',
	) => {
		return {
			onChange: async (x: string) => {
				if (data?.company?.address && x === data.company.address[property]) return;
				if (!data?.company?.address)
					data.company.address = {
						street: '',
						postcode: '',
						country: EuCountryCodes.Fr,
						city: '',
					};
				data.company.address[property] = x;
				await updateCompany({
					companyId: company.id,
					update: { address: { [property]: x } },
				});
				refetch();
			},
		};
	};

	const onUpdateSettings = (update: UpdateCompanySettingsInput) => {
		Object.assign(data.company.settings, update);
		updateCompany({ companyId: company.id, update: { settings: update } })
			.then(() => {
				message.success(tt('Message.Update.Success'));
				refetch();
			})
			.catch((err) => {
				message.error(tt('Message.Update.Error'));
			});
	};

	const setOwnerModalUsers = data.company.users.filter(
		(u) => u.id !== company.admin && u.lastname != null && u.surname != null,
	) as UserInfo[];
	const addUserModalProps: AddUserModalProps<SetCompanyOwnerMutation['setCompanyOwner']> =
		{
			users: setOwnerModalUsers,
			visible: selectOwnerModalVisible,
			mutateAsync: async (userId: number) =>
				(
					await updateAdmin({
						companyId: company.id,
						userId,
					})
				).setCompanyOwner,
			onClose: () => setSelectOwnerModalVisible(false),
			onSuccess: (res) => {
				data.company = res;
			},
		};

	const isOwner = dataSelf.self.id === data.company.owner;

	return (
		<>
			<Helmet>
				<title>{t('Meta.Company.Title', { name: company.name })}</title>
				<meta
					name="description"
					content={t('Meta.Company.Description', { name: company.name })}
				/>
			</Helmet>
			<CreateChannelModal
				companyId={data.company?.id}
				visible={createModalVisible}
				onClose={() => setCreateModalVisible(false)}
				onSuccess={(newChannel) => {
					data.company.channels.push(newChannel);
					setCreateModalVisible(false);
				}}
			/>
			<InviteUserModal
				companyId={data.company.id}
				visible={addModalVisible}
				onClose={() => setAddModalVisible(false)}
			/>
			<AddUserModal {...addUserModalProps} />
			{onboardingVisible ? (
				<OnBoarding
					isShowMask={true}
					onStepsEnd={() =>
						updateOnboarding
							.mutateAsync({ input: { companyCompleted: true } })
							.finally(() => {
								setOnboardingVisible(false);
							})
					}
					steps={[
						{
							selector: () => document.getElementById('companyOverviewChannels'),
							renderContent: () => (
								<div style={{ maxWidth: '400px' }}>
									<Typography.Paragraph>
										{t('CompanyOverview.Onboarding.Channels')}
									</Typography.Paragraph>
								</div>
							),
						},
						{
							selector: () => document.getElementById('companyOverviewUsers'),
							renderContent: () => (
								<div style={{ maxWidth: '400px' }}>
									<Typography.Paragraph>
										{t('CompanyOverview.Onboarding.Users')}
									</Typography.Paragraph>
								</div>
							),
						},
					]}
					locale={{
						locale: dataSelf.self.lang || 'en',
						previous: t('Onboarding.Previous'),
						next: t('Onboarding.Next'),
						gotIt: t('Onboarding.GotIt') + '!',
					}}
				/>
			) : null}
			<Row
				align="middle"
				justify="space-between"
				style={{ textAlign: 'left' }}
				gutter={[16, 16]}
			>
				<Col span={24}>
					<Breadcrumb items={[{ type: 'company', display: company.name }]} />
				</Col>
				<Col span={24}>
					<Row gutter={16} justify="start" align="middle">
						<Col>
							<Title editable={createEditable('name')} level={2} style={{ margin: 0 }}>
								{data.company.name}
							</Title>
						</Col>
						<Col flex="auto">
							<CurrentPlanTag />
						</Col>
						<Col>
							<input
								type="file"
								id="file"
								onChange={onAvatarSelect}
								ref={fileInputElem}
								style={{ display: 'none' }}
							/>
							<Popover className="TEST" content={t('CompanyOverview.Avatar.Popover')}>
								<UserAvatar
									type="rectangle"
									getRefetchHandle={(handle) => setRefetchHandle(handle)}
									onClick={() => openFileInput()}
									size={96}
									user={avatarData}
									className="BoxShadow"
									style={{ borderRadius: '16px' }}
								/>
							</Popover>
						</Col>
					</Row>
					<Row justify="end"></Row>
				</Col>
				<Col xs={24} lg={12}>
					<Title level={5}>
						Code:&nbsp;
						<Text
							style={{ display: 'inline-block', width: '100px' }}
							editable={createEditable('code', 5)}
						>
							{company.code}
						</Text>
						URL-Slug:&nbsp;
						<Text code>{data.company.slug}</Text>
					</Title>
					<Title level={4}>{t('AddressFormItem.Label')}</Title>
					<Space direction="vertical" size="middle">
						<Space>
							<Text style={{ width: '200px', display: 'block' }} strong>
								{t('AddressFormItem.Name')}
							</Text>
							<Text editable={createAddressEditable('recipientName')}>
								{company.address?.recipientName}
							</Text>
						</Space>
						<Space>
							<Text style={{ width: '200px', display: 'block' }} strong>
								{t('AddressFormItem.Street')}
							</Text>
							<Text editable={createAddressEditable('street')}>
								{company.address?.street}
							</Text>
						</Space>
						<Space>
							<Text style={{ width: '200px', display: 'block' }} strong>
								{t('AddressFormItem.Housenumber')}
							</Text>
							<Text editable={createAddressEditable('housenumber')}>
								{company.address?.housenumber}
							</Text>
						</Space>
						<Space>
							<Text style={{ width: '200px', display: 'block' }} strong>
								{t('AddressFormItem.City')}
							</Text>
							<Text editable={createAddressEditable('city')}>
								{company.address?.city}
							</Text>
						</Space>
						<Space>
							<Text style={{ width: '200px', display: 'block' }} strong>
								{t('AddressFormItem.Postcode')}
							</Text>
							<Text editable={createAddressEditable('postcode')}>
								{company.address?.postcode}
							</Text>
						</Space>
					</Space>
				</Col>
				<Col xs={24} lg={12}>
					<CompanySettings
						company={data.company}
						update={onUpdateSettings}
						enabled={accessLevelAbove(AccessLevel.Admin, data.company.selfAccessLevel)}
					/>
				</Col>
			</Row>
			<Divider />
			<Row id="companyOverviewChannels">
				<Col span={24}>
					<Space direction="vertical" style={{ width: '100%' }}>
						<Row gutter={[8, 8]}>
							<Col>
								<Title level={3}>{t('Channels')}</Title>
							</Col>
							<Col flex="auto"></Col>
							<Col>
								<FeatureLock featureName={FeatureNames.MultipleChannels}>
									<Button onClick={() => setCreateModalVisible(true)}>
										{t('Create')}
									</Button>
								</FeatureLock>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							{data.company.channels.map((c: any) => (
								<Col key={c.id}>
									<ChannelCard channel={c} />
								</Col>
							))}
						</Row>
					</Space>
				</Col>
			</Row>
			<Divider />
			<Row id="companyOverviewUsers">
				<Col span={24}>
					<Space direction="vertical" style={{ width: '100%' }}>
						<Row gutter={[16, 8]}>
							<Col>
								<Title level={3}>{t('Users')}</Title>
							</Col>
							<Col flex="auto"></Col>
							<Col>
								{isOwner || company.selfAccessLevel === AccessLevel.Admin ? (
									<FeatureLock featureName={FeatureNames.MultipleUsers}>
										<Button onClick={() => setAddModalVisible(true)}>{t('Add')}</Button>
									</FeatureLock>
								) : null}
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<List
									size="small"
									dataSource={data.company.members}
									renderItem={(member: CompanyQuery['company']['members'][0]) => {
										// TODO: add transfer Ownership action to menu
										const menuItems: React.ReactNode[] = [];
										if (
											dataSelf.self.id !== member.user.id &&
											(isOwner ||
												(company.selfAccessLevel === AccessLevel.Admin &&
													member.accessLevel !== AccessLevel.Admin))
										) {
											menuItems.push(<Menu.Item key="remove">{t('Remove')}</Menu.Item>);
										}
										const menu =
											menuItems.length > 0 ? (
												<Dropdown
													key={0}
													trigger={['click']}
													overlay={
														<Menu
															style={{ overflow: 'hidden' }}
															onClick={({ key }) => {
																if (key === 'remove') removeUser(data.company, member);
															}}
														>
															{menuItems}
														</Menu>
													}
												>
													<MoreOutlined />
												</Dropdown>
											) : undefined;

										return (
											<List.Item actions={menu ? [menu] : []}>
												<List.Item.Meta
													style={{ textAlign: 'left' }}
													avatar={
														<UserAvatar
															key={member.user.id}
															user={{
																surname: member.user.surname,
																lastname: member.user.lastname,
																id: member.user.avatar?.id,
															}}
														/>
													}
													title={member.user.surname + ' ' + member.user.lastname}
												/>
												<Space>
													{company.owner === member.user.id ? (
														<Tag color="magenta">{t('Owner')}</Tag>
													) : (
														<TagAccessLevel
															level={member.accessLevel}
															editable={
																company.owner === dataSelf.self.id ||
																company.selfAccessLevel === AccessLevel.Admin
																	? { companyId: company.id, userId: member.user.id }
																	: undefined
															}
														/>
													)}
												</Space>
											</List.Item>
										);
									}}
								/>
							</Col>
						</Row>
						<PendingInvites {...companyInvitesQuery} />
					</Space>
				</Col>
			</Row>
			<Divider />
			{accessLevelAbove(AccessLevel.Admin, selfAccessLevel) && (
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Row justify="space-between">
							<Col>
								<Typography.Title level={3}>{tt('Administration')}</Typography.Title>
							</Col>
							<Col>
								<Dropdown
									key={0}
									trigger={['click']}
									overlay={
										<Menu
											onClick={({ key }) => {
												if (key === 'remove') setShowRemoveCompanyModal(true);
											}}
											style={{ overflow: 'hidden' }}
										>
											<Menu.Item key="remove">{tt('RemoveCompany.Button')}</Menu.Item>
										</Menu>
									}
									placement="bottomRight"
								>
									<Button icon={<MoreOutlined />} />
								</Dropdown>
								<RemoveCompanyModal
									show={showRemoveCompanyModal}
									id={company.id}
									name={company.name}
									onClose={() => setShowRemoveCompanyModal(false)}
								/>
							</Col>
						</Row>
					</Col>
					{!companyApiKeysFeatureLocked && (
						<Col span={24}>
							<CompanyApiKeys />
						</Col>
					)}
				</Row>
			)}
		</>
	);
};
